import React from "react";

const Footer = () => {
  return (
    <footer className="bg-primary py-8 text-center text-white">
      <div className="container mx-auto">CopyRight &copy; 2022. All Right Reserve.</div>
    </footer>
  );
};

export default Footer;
