import React from 'react';
import { housesData } from '../data';
import { useParams } from 'react-router-dom';
import { BiBed, BiBath, BiArea } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const PropertyDetails = () => {
  //get house id
  const { id } = useParams();

  //get house based on the id
  const house = housesData.find((house) => {
    return house.id === parseInt(id);
  });
  console.log(house);

  return <sectioon>
    <div className="container mx-auto min-h-[800px] mb-14">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div>
          <h2 className="text-2xl font-semibold">{house.name}</h2>
          <h2 className="text-lg mb-4">{house.address}</h2>
        </div>
        <div className="mb-4 lg:mb-0 flex gap-x-2 text-sm">
          <div className="bg-green-500 text-white px-3 rounded-full">{house.type}</div>
          <div className="bg-primary text-white px-3 rounded-full">{house.country}</div>
        </div>
        <div className="text-3xl font-semibold text-primary">Rp. {house.price}</div>
      </div>
      <div className="flex flex-col items-start gap-8 lg:flex-row">
        <div className="max-w-[768px]">
          <div className="mb-8">
            <img className="rounded-md" src={house.imageLg} alt=''/>
          </div>
          <div className="flex gap-x-6 text-primary mb-6">
            <div className="flex gap-x-2 items-center">
              <BiBed className="text-2xl" />
              <div>{house.bedrooms}</div>
            </div>
            <div className="flex gap-x-2 items-center">
              <BiBath className="text-2xl" />
              <div>{house.bathrooms}</div>
            </div>
            <div className="flex gap-x-2 items-center">
              <BiArea className="text-2xl" />
              <div>{house.surface}</div>
            </div>
          </div>
          <div>{house.description}</div>
        </div>
        <div className="flex-1 bg-white w-full mb-8 border border-secondary rounded-lg px-6 py-8">
          <div className="flex items-center gap-x-4 mb-8">
            <div className="w-20 h-20 border p-1 border-secondary rounded-full">
              <img src={house.agent.image} alt=''/>
            </div>
            <div>
              <div className="font-bold text-lg">{house.agent.name}</div>
              <Link to='' className="text-primary text-sm">View Listing</Link>
            </div>
          </div>
          <form className="flex flex-col gap-y-4">
            <input className="border border-secondary focus:border-primary outline-none rounded w-full px-4 h-14 text-sm" 
            type="text"
            placeholder="Name*"
            />
            <input className="border border-secondary focus:border-primary outline-none rounded w-full px-4 h-14 text-sm" 
            type="text"
            placeholder="Email*"
            />
            <input className="border border-secondary focus:border-primary outline-none rounded w-full px-4 h-14 text-sm" 
            type="text"
            placeholder="Phone*"
            />
            <textarea className="border border-secondary focus:border-primary outline-none rounded w-full p-4 h-28 text-sm text-gray-400"
            placeholder="Message*"
            defaultValue="Hello, i am interested in Modern Apartment"
            ></textarea>
            <div className="flex gap-x-2">
              <button className="bg-primary  hover:bg-secondary  text-white rounded p-4 text-sm w-full transition">Send message</button>
              <button className="border border-primary  hover:border-secondary hover:text-secondary rounded p-4 text-sm w-full transition">Call</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </sectioon>;
};

export default PropertyDetails;
