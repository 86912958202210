import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.svg';

const Header = () => {
  return (
  <header className="py-6 mb-12 border-b"> 
    <div className="container mx-auto flex justify-between items-center">
      <Link to="/">
        <div className="flex">
          <div className="text-dark font-bold text-2xl">Gi</div>
          <div className="text-primary font-bold text-2xl">Home</div>
        </div>
        {/* <img src={Logo} alt="" /> */}
      </Link>
      <div className="flex items-center gap-6">
        <Link className="hover:text-secondary transition" to=''>Log in</Link>
        <Link className="bg-primary hover:bg-secondary text-white px-4 py-3 rounded-lg transition" to=''>Sign Up</Link>
      </div>
    </div>
  </header>
  );
};

export default Header;
