import React, { useState, useEffect, useContext } from "react";
import { RiWallet3Line, RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Menu } from "@headlessui/react";
import { HouseContext } from "./HouseContext";

const PriceRangeDropdown = () => {
  const { price, setPrice } = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);
  const prices = [
    {
      value: 'Kisaran harga (semua)',
    },
    {
      value: '100000000 - 130000000',
    },
    {
      value: '130000000 - 160000000',
    },
    {
      value: '160000000 - 190000000',
    },
    {
      value: '190000000 - 220000000',
    },
    {
      value: '10000000 - 30000000',
    },
    {
      value: '30000000 - 40000000',
    },
  ]

  return (
    <Menu as="div" className="dropdown relative">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-btn w-full text-left"
      >
        <RiWallet3Line className="dropdown-icon-primary" />
        <div>
          <div className="text-[15px] font-medium leading-tight">Rp. {price}</div>
          <div className="text-[13px]">Pilih kisaran harga</div>
        </div>
          {isOpen ? (
            <RiArrowUpSLine className="dropdown-icon-secondary" />
          ) : (
            <RiArrowDownSLine className="dropdown-icon-secondary" />
          )}
      </Menu.Button>

      <Menu.Items className="dropdown-menu">
        {prices.map((price, index) => {
          return (
            <Menu.Item onClick={() => setPrice(price.value)} className="cursor-pointer hover:text-primary transition" as="li" key={index}>
              Rp. {price.value}
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default PriceRangeDropdown;
