import React, { useState, useEffect, createContext } from 'react';
import { houseData } from '../data';
import { housesData } from '../data';
import { parse } from 'postcss';

export const HouseContext = createContext();

const HouseContextProvider = ({ children }) => {
  const [houses, setHouses] = useState(housesData);
  const [country, setCountry] = useState('Lokasi (semua)');
  const [countries, setCountries] = useState([]);
  const [property, setProperty] = useState('Tipe properti (semua)');
  const [properties, setProperties] = useState([]);
  const [price, setPrice] = useState('Kisaran harga (semua)');
  const [loading, setLoading] = useState(false);

  //return all countries
  useEffect(() => {
    const allCountries = houses.map((house) => {
      return house.country;
    });

    //remoce duplicate 
    const uniqueCountries = ['Lokasi (semua)', ...new Set(allCountries)];

    //set countries state
    setCountries(uniqueCountries);
  }, []);

  //return all properties
  useEffect(() => {
    const allProperties = houses.map((house) => {
      return house.type;
    });

    //remoce duplicate 
    const uniqueProperties = ['Tipe properti (semua)', ...new Set(allProperties)];

    //set properties state
    setProperties(uniqueProperties);
  }, []);

    const handleClick = () => {
      setLoading(true);
      const isDefault = (str) => {
        return str.split(' ').includes('(semua)');
      };

      const minPrice = parseInt(price.split(' ')[0]);
      const maxPrice = parseInt(price.split(' ')[2]);

     
      const newHouses = housesData.filter((house) => {
        const housePrice = parseInt(house.price);

        //if all value are selected
        if (
          house.country === country &&
          house.type === property &&
          housePrice >= minPrice &&
          housePrice <= maxPrice 
        ) {
          return house;
        }

        //if all value are defalut
        if (isDefault(country) && isDefault(property) && isDefault(price)){
          return house;
        }

        //if country not default
        if (!isDefault(country) && isDefault(property) && isDefault(price)){
          return house.country === country;
        }

        //if property not defalut
        if (!isDefault(property) && isDefault(country) && isDefault(price)){
          return house.type === property;
        }

        // if price is not default
        if (!isDefault(price) && isDefault(country) && isDefault(property)){
          if (housePrice >= minPrice && housePrice <= maxPrice) {
            return house;
          }
        }

        //if country & property not defalut
        if (!isDefault(country) && !isDefault(property) && isDefault(price)){
          return house.country === country && house.type === property;
        }

        //if country & price not defalut
        if (!isDefault(country) && isDefault(property) && !isDefault(price)){
          if (housePrice >= minPrice && housePrice <= maxPrice) {
            return house.country === country;
          }
        }

        //if property & price not defalut
        if (isDefault(country) && !isDefault(property) && !isDefault(price)){
          if (housePrice >= minPrice && housePrice <= maxPrice) {
            return house.type === property;
          }
        }

      });
      
      setTimeout(() => {
        return newHouses.length < 1 ? setHouses([]) : setHouses(newHouses),
        setLoading(false);
      });
    };

  return <HouseContext.Provider value={{
    country,
    setCountry,
    countries,
    property,
    setProperty,
    properties,
    price,
    setPrice,
    houses,
    loading,
    handleClick,
    loading,

  }}>
      {children}
    </HouseContext.Provider>;
};

export default HouseContextProvider;
